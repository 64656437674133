
import fnApi from '/utils/common/methods'

export default {
    name: 'PaySuccess',
    layout: 'main-head',
    data() {
        return {
            skeleton: true,
            hasPull: false,          // 节流
            hasApp: false,           // 是否在app内
            fnApi: fnApi,
            orderInfo: {},           // 订单信息
            info: {                  // 订单设置数据
                statusFill: '',
                orderStatus: 1,      // 订单状态
            },
            address: {},             // 地址信息,
            member: {},              // 会员信息
            ad: {                    // 广告
                visible: false,
                list: []
            },
            query: {},               // 地址栏参数
            log: {                   // 日志
                fbq: {}
            },
            page: {                  // 分页
                totalPage: 0 ,
                pageNum: 1,
                pageSize: 20,
                showPosition: 2
            },
            listCompo: {             // list组价配置
                list: [],
                options: {
                    hasMore: true
                }
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getAdAjax()
        this.getRecommendAjax()
    },
    methods: {
        // 获取订单详情
        getOrderInfoAjax() {
            this.$api.order.getOrderInfo({
                id: this.query.order_id,
                time: new Date().getTime()
            }).then(response => {
                const res = response.result || {};
                this.orderInfo = {
                    ...this.orderInfo,
                    ...res,
                }
                this.$storage.remove('biz/inPopup')
                this.setOrderInfo()
                // 地址
                this.setAddress()
                // 埋点
                this.setBuried()
            })
        },
        // 广告接口
        getAdAjax() {
            this.$api.common.getAd({
                places: 5
            }).then(response => {
                const res = response.result || [];
                this.ad.list = res || []
                this.ad.visible = this.ad.list.length
            })
        },
        // 获取推荐
        getRecommendAjax() {
            this.$api.common.getRecV5Common({
                index: 6,
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
            }).then(response => {
                const res = response.result || {};
                this.listCompo.list = [...this.listCompo.list, ...(res || [])]
                this.page.totalPage = res.totalPage
            })
        },
        // 空中云汇支付回调接口
        awPayAjax() {
            this.$api.order.getAWPay({
                orderId: this.query.order_id,
                payId: this.query.id,
                payStatus: this.info.orderStatus
            }).then(response => {
                const res = response.result || {};
                this.orderInfo = {
                    ...this.orderInfo,
                    ...res,
                    buyerEmail: res.email
                }
                this.orderInfo.amtCent = this.orderInfo.amtCent || this.orderInfo.amount
                this.setOrderInfo()
                // 地址
                this.setAddress()
            })
        },
        // 订单信息设置
        setOrderInfo() {
            this.skeleton = false
            // orderStatus 1 成功 2 正在支付 3 失败 4 取消
            this.info.statusTitle = `${this.$translate('Payment')} ${this.$translate(['Successful', 'Pending', 'Failed', 'Canceled'][this.info.orderStatus - 1])}`
            if(this.orderInfo.paymentMethod == 'COD') {
                this.info.statusTitle = this.$translate('Order Placed Successfully')
            }
            this.info.statusClass = `${['icon-check-selected', 'icon-warn', 'icon-close-black', 'icon-close-black'][this.info.orderStatus - 1]}`
            this.info.statusFill = `${['#96BD2C', '#F8981C', '#EC4E25', '#EC4E25'][this.info.orderStatus - 1]}`
            this.info.tips = this.$translate([
                // `Thank you for shopping with us. An Order Confirmation email has been sent to ${this.orderInfo.buyerEmail}`,
                `Confirmation and shipment updates will be sent to`,
                `Your payment is pending now, please be patience and we will also notify you via email about the payment`,
                `Sorry, this payment failed, you can try again or change the payment method`,
                `You have cancelled your payment`,
                ][this.info.orderStatus - 1])
            // 按钮
            this.info.confirmBtn = this.$translate(this.info.orderStatus == 3 ? 'Change Payment' : 'Continue Shopping')
            this.info.cancelBtn = this.$translate(this.info.orderStatus == 3 ? 'Try Again' : 'View Order Detail')
            if(this.info.orderStatus == 4) {
                if(this.hasApp) {
                    this.fnApi.bridgeApp.bridge({
                        name: 'setBack'
                    })
                } else {
                    this.$router.push({
                        name: 'OrderConfirm',
                        query: {
                            orderFrom: this.info.orderFrom || 'cart'
                        }
                    })
                }
                return
            }
            this.removeData()
        },
        // 地址
        setAddress() {
            const address = this.orderInfo.yfnAddress;
            this.address = address ? JSON.parse(address) : {}
        },
        // 支付
        setAWPay() { // 空中云汇支付
            let type = (this.query.type && this.query.type.toLowerCase()) || 'success'
            type.includes('success') && (this.info.orderStatus = 1)
            type.includes('pending') && (this.info.orderStatus = 2)
            type.includes('fail') && (this.info.orderStatus = 3)
            type.includes('cancel') && (this.info.orderStatus = 4)

            if([3, 4].includes(this.info.orderStatus))  {
                this.getOrderInfoAjax()
            } else {
                this.awPayAjax()
            }
        },
        setPayssionPay() { // payssion支付
            this.$api.order.getPaySSionPay({
                transactionId: this.query.transaction_id,
                orderId: this.query.order_id
            }).then(response => {
                const res = response.result || {};
                if(response.code == "error"){
                    this.getOrderInfoAjax()
                    return
                }
                let state = res.transaction.state
                if(state.includes('completed')) {
                    // 成功
                    this.info.orderStatus = 1
                } else if(state.includes('pending')) {
                    // 等待
                    this.info.orderStatus = 2
                } else if(state.includes('cancelled')) {
                    // 取消
                    this.info.orderStatus = 4
                } else{
                    // 失败
                    this.info.orderStatus = 3
                }
                this.getOrderInfoAjax()
            })
        },
        // 清除缓存
        removeData() {
            const keyArr = [
                'order/billAddress',
                'order/cardIcon',
                'order/cardPayInfo',
                'order/coupon',
                'order/payMethod',
                'order/placeAddress',
                'order/placeProduct',
                'order/placeGiftCard',
                'order/placeGiftCardParams',
                'order/placePage',
                'order/remarks',
                'order/shippingMethod',
                'spm'];
            keyArr.forEach(key => {
                this.$storage.remove(key)
            })
        },
        // 按钮操作
        handleCancel() { // 取消
            if(this.info.orderStatus == 3) {
                if(this.hasApp) {
                    this.fnApi.bridgeApp.bridge({
                        name: 'setBack'
                    })
                } else {
                    this.$router.back()
                }
                return
            }
            if(this.info.orderStatus == 4) {
                if(this.hasApp) {
                    this.fnApi.bridgeApp.bridge({
                        name: 'setBack'
                    })
                } else {
                    this.$router.push({
                        name: 'OrderConfirm',
                    })
                }
                return
            }
            if(this.hasApp) {
                this.fnApi.bridgeApp.bridge({
                    name: 'goOrderDetail',
                    params: {
                        orderId: this.query.order_id
                    }
                })
            } else {
                this.$router.push({
                    name: 'OrderDetail',
                    query: {
                        id: this.query.order_id,
                        type: 1
                    }
                })
            }
        },
        handleSuccess() { // 成功
            if([3, 4].includes(this.info.orderStatus)) {
                if(this.hasApp) {
                    this.fnApi.bridgeApp.bridge({
                        name: 'setBack'
                    })
                } else {
                    this.$router.push({
                        name: 'OrderConfirm'
                    })
                }
                return
            }
            if(this.hasApp) {
                this.fnApi.bridgeApp.bridge({
                    name: 'goHome'
                })
            } else {
                this.$router.push('/')
            }
        },
        // 广告
        goAd(obj) { // 广告跳转
            window.location.href = obj.jumpUrl
        },
        // 触底加载
        emitBottomLoading(e, callBack) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                this.getRecommendAjax()
            }
            callBack(!hasNext)
        },
        // 埋点
        setSymbolReplace(val) {
            try {
                return val && val.includes(',') ? val.replaceAll('.', '').replaceAll(',', '.').replace(/[^\d.-]/g, '') : val
            } catch {
                return val
            }
        },
        setBuried() {
            const utm = this.$storage.get('marketUtm') || {};
            const list = this.orderInfo.yfnOrderItem|| [];
            const phone = this.orderInfo.mobileNumber && `${this.orderInfo.mobileNumber.split('-').join('')}` || '';
            let fb = {};
            fb.contents = []
            fb.currency = 'USD'
            fb.content_type = 'product_group'
            fb.value = (this.orderInfo.amtCent / 100).toFixed(2)
            fb.num_items = this.orderInfo.quantity
            let mitgo = [];
            // let criteo = [];
            let tiktok = [];
            let gaCom = [];
            let gtag = [];
            list.forEach((p,index) => {
                // gtag
                gtag.push({
                    group_item_id: p.spuCode,
                    item_id: p.yfnSkuId,
                    item_name: p.Title || p.title,
                    quantity: p.quantityOrdered,
                    price: (p.itemAmount / 100).toFixed(2),
                    index: index,
                    affiliation: 'YFN',
                    item_brand: 'YFN',
                    item_variant: p.sellerSku,
                })
                // fb
                fb.contents.push({
                    id: p.spuCode,
                    quantity: p.quantityOrdered
                })
                // mitgo
                mitgo.push({
                    Product: {
                        productID: p.spuCode,
                        category: '1',
                        price: p.itemAmount / 100,
                        priceCurrency: 'USD',
                    },
                    orderQuantity: p.quantityOrdered,
                    additionalType: 'sale'
                })
                // criteo
                // criteo.push({
                //     id: p.spuCode,
                //     price: p.itemPrice.amount,
                //     quantity: p.quantityOrdered,
                // })
                // tiktok
                tiktok.push({
                    content_type: 'product',
                    quantity: p.quantityOrdered,
                    content_id: p.spuCode,
                    currency: this.orderInfo.currency,
                    price: p.itemPrice.amount,
                    value: p.itemPrice.amount
                })
                // googleComment
                gaCom.push({
                    id: p.spuCode,
                    title: p.title,
                    description: p.title,
                    link: `https://${location.host}/item/${p.spuCode}/${p.title}`,
                    image_link: p.mainPic,
                    price: p.itemPrice.amount,
                    MPN: p.sellerSku
                })
            })
            // gtag
            this.$fnApi.buried.gtag({
                type: 'user_data',
                params: {
                    "email": this.orderInfo.buyerEmail,
                    "phone_number": phone
                }
            })
            // <!-- Event snippet for 购买 conversion page -->
            this.$fnApi.buried.gtag({
                type: 'conversion_purchase',
                params: {
                    'value': this.setSymbolReplace((this.orderInfo.amtCent / 100).toFixed(2)),
                    'currency': 'USD',
                    'transaction_id': this.orderInfo.originOrderId,
                    "email": this.orderInfo.buyerEmail,
                }
            })
            this.$fnApi.buried.gtag({
                type: 'purchase',
                params: {
                    transaction_id: this.orderInfo.originOrderId,
                    value: Number(this.setSymbolReplace((this.orderInfo.amtCent / 100).toFixed(2))),
                    currency: 'USD',
                    shipping: 0,
                    items: gtag
                }
            })
            //FB
            this.$fnApi.buried.fbq({
                type: 'Purchase',
                params: fb,
                eventID: this.orderInfo.originOrderId
            }, this)
            // bing
            this.$fnApi.buried.bing({
                type: 'purchase',
                params: {
                    revenue_value: this.setSymbolReplace(this.orderInfo.displayAmtCent),
                    currency: this.orderInfo.currency
                }
            })
            // mitgo 埋点
            this.$fnApi.buried.mitgo({
                type: 'user_data',
                params: {
                    email: this.orderInfo.buyerEmail
                }
            })
            this.$fnApi.buried.mitgo({
                type: 'purchase',
                params: {
                    orderNumber: this.orderInfo.originOrderId,
                    orderedItem: mitgo
                }
            })
            // criteo
            // this.$fnApi.buried.criteo({
            //     type: 'trackTransaction',
            //     id: this.orderInfo.originOrderId,
            //     params: criteo
            // })
            // tiktok
            this.$fnApi.buried.tiktok({
                type: 'CompletePayment',
                params: {
                    content_type: 'product',
                    contents: tiktok,
                    currency: this.orderInfo.currency,
                    value: this.setSymbolReplace(this.orderInfo.displayAmtCent)
                }
            })
            // awin/shareSale
            let autovoid = '';
            if(utm.utm_source == 'awin') {
                // awin
                let tag = document.createElement('img');
                tag.src = `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=32525&amount=${this.orderInfo.amtCent / 100}&cr=USD&ref=${this.orderInfo.originOrderId}&parts=default:${this.orderInfo.amtCent / 100}&vc=${this.orderInfo.couponCode || ''}&ch=awin&testmode=0`
                tag.border = 0
                tag.width = 0
                tag.height = 0
                this.$fnApi.buried.awin({
                    orderId: this.orderInfo.originOrderId,
                    amount: this.orderInfo.amtCent / 100,
                    tag,
                })
                autovoid = '&autovoid=1'
            } else {
                // shareSale
                let tag = document.createElement('img');
                tag.src = `https://www.shareasale.com/sale.cfm?tracking=${this.orderInfo.originOrderId}&amount=${this.orderInfo.amtCent / 100}&merchantID=118663&transtype=sale${autovoid}&currency=USD&couponcode=${this.orderInfo.couponCode || ''}`
                tag.width = 0
                tag.height = 0
                this.$fnApi.buried.shareSale({
                    tag
                })
            }
            // googleComment
            this.$fnApi.buried.googleComment({
                ...this.orderInfo,
                items: gaCom,
                time: this.$dayjs(this.orderInfo.gmtLatestDelivery).format('YYYY-MM-DD'),
            })
            // goaffproTrackConversion
            this.$fnApi.buried.goaffpro({
                params: {
                    number : this.orderInfo.originOrderId,
                    coupons: this.orderInfo.couponCode ? [this.orderInfo.couponCode] : [],
                    total: this.orderInfo.displayAmtCent
                }
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Payment Successful'),
                keywords: this.$translate('Payment Successful'),
                description: this.$translate('Payment Successful'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query || {}
            !this.query.order_id && (this.query.order_id = this.query.id)
            this.info.statusClass = `icon-warn`
            this.hasApp = this.fnApi.bridgeApp.getApp().isApp
            let type = (this.query.type && this.query.type.toString().toLowerCase()) || 'success'
            let hasAW = false; // 是否空中云汇
            ['success', 'pending', 'fail', 'cancel'].some(item => {
                if(type.includes(item)) {
                    hasAW = true
                    return true
                }
            })
            if(this.query.type) {
                if(hasAW) {
                    // 空中云汇支付
                    this.setAWPay()
                } else {
                    // payssion支付
                    this.setPayssionPay()
                }
            } else {
                this.getOrderInfoAjax()
            }
        }
    },
}
